import styled from "styled-components"
import React from "react"
import ReactTooltip from "react-tooltip"

const StyledTooltip = styled(ReactTooltip)`
  font-size: 1.4rem !important;
  background: var(--BLUE) !important;
  border-radius: 10px;
  max-width: 180px;
  text-align: center;

  &.place-top:after {
    border-top-color: var(--BLUE) !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
  }
`

const Tooltip = ({ id }) => {
  return (
    <StyledTooltip
      clickable={true}
      effect="solid"
      delayHide={100}
      wrapper="span"
      id={id}
    />
  )
}

export { Tooltip as default }
